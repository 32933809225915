<template>
    <div v-if="!$store.getters.get_is_post_loading" v-for="post in getFeed" class="feed">
        <div class="avatar">
            <img :src="avatarCalc(post.avatar)" alt="">
            <div class="text">
                <router-link class="text-decoration-none" :to="'/u/' + post.username">
                    <div class="name">{{ post.fullname }}</div>
                </router-link>
                <div class="area">{{ post.title }}</div>
            </div>
            <div :style="{ color: $store.getters.getNumberColor(post.dayCount) }" class="total-day"
                :class="{ 'my-gradient-text': post.dayCount > 4500 }">{{ post.dayCount }}</div>
        </div>
        <!--  <div class="title-side">
            <div class="title">{{ post.title }}</div>
        </div>-->
        <div class="description">{{ post.content }}</div>
        <div class="icons">
            <button @click="toggleLike(post)" class="likeButton">
                <img v-if="post.is_like" src="@/assets/Images/Feed/liked.svg">
                <img v-else src="@/assets/Images/Feed/like-dark.svg" alt="">
            </button>
            <span class="like-length">{{ post.like_list.length }}</span>
            <button @click="post.isOpen = !post.isOpen;">
                <img src="@/assets/Images/Feed/comment-dark.svg" alt="">
            </button>
            <span class="comment-length">{{ post.comment_list.length }}</span>
            <div class="date">{{ new Date(post.createdAt).toLocaleDateString('tr-TR') }}</div>
        </div>
        <Comment :post="post" v-if="post.isOpen" />
    </div>
    <NothingYet v-if="!$store.getters.get_is_post_loading && this.getFeed.length == 0"
        style="width: 400px; height: 400px;" />
    <Loading style="width: 300px; height: 300px;" v-if="$store.getters.get_is_post_loading"></Loading>
</template>
<script>
import Comment from '@/components/Feed/Comment.vue'
import NothingYet from '@/components/Shared/NothingYet.vue'
import Loading from '@/components/Shared/Loading.vue'
export default {
    name: 'Post',
    data() {
        return {
            isOpen: false,
            username: this.$store.getters.get_user_data.username
        }
    },
    components: {
        Comment,
        NothingYet,
        Loading
    },
    methods: {
        toggleLike(post) {
            if (post.is_like) {
                this.$store.dispatch("dislike", post.id);
                var foundIndex = this.getFeed.findIndex(x => x.id == post.id);
                this.getFeed[foundIndex].is_like = false
                this.getFeed[foundIndex].like_list = this.getFeed[foundIndex].like_list.filter(item => item !== this.username)

            } else {
                this.$store.dispatch("like", post.id);
                var foundIndex = this.getFeed.findIndex(x => x.id == post.id);
                this.getFeed[foundIndex].is_like = true
                this.getFeed[foundIndex].like_list.push(this.username)
            }
        },
        avatarCalc(avatar_link) {
            if (avatar_link) {
                return avatar_link
            }
            return require("@/assets/Images/Header/avatar_thumbnail.png");
        },
    },
    mounted() {


    },
    created() {
        this.$store.dispatch("download_feed_posts")

    },
    computed: {
        getFeed() {
            return this.$store.getters.get_feed_posts
        }
    }
}
</script>
<style scoped>
.feed {
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    background-color: #FEFBF6;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
    justify-content: space-around;
    border-radius: 16px;
    padding: 16px;
    gap: 16px;
}

.feed .title-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* color: #212529; */
    color: #6C757D;
    margin-top: 16px;
}

.feed .title {
    font-weight: 600;
}

.feed .description {
    /* color: #212529; */
    color: #6C757D;
    font-size: 16px;
    font-weight: 300;
    white-space: pre-line
}



.feed .avatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding-bottom: 8px;
    border-bottom: #212529 1px solid;
    position: relative;

}

.feed .avatar img {
    width: 60px;
    height: 60px;
    background-color: #212529;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
}

.feed .avatar .text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* color: #212529; */
    color: #6C757D;
    width: fit-content;
}

.feed .avatar .text .name {
    font-size: 20px;
}

.feed .avatar .total-day {
    align-self: center;
    margin-left: 80px;
    font-weight: 600;
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.feed .icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
    position: relative;
}

.feed .icons button {
    border: none;
    background-color: transparent;
}

.feed .icons button:hover {
    cursor: pointer;
}

.like-length,
.comment-length {
    color: #6C757D;
}

.feed .icons .date {
    /* color: #212529; */
    color: #6C757D;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    bottom: 0px;
    right: 16px;
}
</style>