<template>
  <div>
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from './components/Shared/Header';
import Footer from './components/Shared/Footer';
export default {
  data() {
    return {

    }
  },
  created() {
    this.$store.commit("init_auth")

  },
  components: {
    Header: Header,
    Footer: Footer
  },
}

</script>
<style>
@import url("@/assets/css/buttons.css");

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins;
  background-color: #FEFBF6;
}

* {
  box-sizing: border-box;
}

.text-decoration-none {
  text-decoration: none;
}

@font-face {
  font-family: Poppins;
  src: url('./assets/Fonts/Poppins-Regular.ttf');
}

.my-gradient-text {
  background: -webkit-linear-gradient(45deg, #7b2cbf, #ff6d00 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
