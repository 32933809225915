import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { appAxios } from "./utils/appAxios";
import { createHead } from "@unhead/vue";
import { Head } from "@unhead/vue/components";

//axios.defaults.baseURL = "https://aho-api-render.onrender.com/api";
const head = createHead();

const app = createApp(App);
app.use(store);
app.use(router);
app.use(head);
app.component("Head", Head);
app.config.globalProperties.$appAxios = appAxios;
app.mount("#app");
