import { createStore } from "vuex";
import home from "./modules/home";
import auth from "./modules/auth";
import comment from "./modules/comment";
import newPost from "./modules/newPost";
import profile from "./modules/profile";

export default createStore({
  state: {
    // site geneli store lar
    seriesList: [],
    moviesList: [],
  },
  getters: {
    getSeriesList(state) {
      return state.seriesList;
    },
    getMoviesList(state) {
      return state.moviesList;
    },
    getNumberColor: (state) => (number) => {
      if (number >= 0 && number <= 100) {
        return "#7b2cbf";
      } else if (number > 100 && number <= 500) {
        return "#A50104";
      } else if (number > 500 && number <= 1000) {
        return "#EC3F13";
      } else if (number > 1000 && number <= 1500) {
        return "#FF7E33";
      } else if (number > 1500 && number <= 2000) {
        return "#FFAD33";
      } else if (number > 2000 && number <= 2500) {
        return "#CCFF33";
      } else if (number > 2500 && number <= 3000) {
        return "#9EF01A";
      } else if (number > 3000 && number <= 3500) {
        return "#70E000";
      } else if (number > 3500 && number <= 4000) {
        return "#008000";
      } else if (number > 4000 && number <= 4500) {
        return "#006400";
      }
    },
  },
  mutations: {
    // state güncellemek için çalıştırılır. Senkron çalışır
    UpdateSeriesList(state, payload) {
      state.seriesList = payload;
    },
    UpdateMoviesList(state, payload) {
      state.moviesList = payload;
    },
  },
  actions: {},
  modules: {
    home,
    auth,
    comment,
    newPost,
    profile,
  },
});
