import { appAxios } from "@/utils/appAxios";
import router from "../../router/index";

const state = {
  active_auth_page: "Register",
  user_data: null,
  is_me: false,
  profile_data: {},
  active_profile: {},
  is_pro_profile: true,
};
const getters = {
  // state verisini çağırmak için kullanılır

  get_active_auth_page(state) {
    return state.active_auth_page;
  },
  get_user_data(state) {
    return state.user_data;
  },
  get_profile_data(state) {
    return state.profile_data;
  },
  is_authenticated(state) {
    return state.user_data != null;
  },
  get_is_me(state) {
    return state.is_me;
  },
  get_active_profile(state) {
    return state.active_profile;
  },
  is_pro_profile(state) {
    return state.is_pro_profile;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  go_login(state) {
    state.active_auth_page = "Login";
    router.push("/auth");
  },

  go_register(state) {
    state.active_auth_page = "Register";
    router.push("/auth");
  },
  set_user_data(state, payload) {
    state.user_data = payload;
    appAxios.defaults.headers.common["Authorization"] =
      "Token " + payload.token;
    localStorage.setItem("user_data", JSON.stringify(payload));
  },
  init_auth(state) {
    let local_user_data = JSON.parse(localStorage.getItem("user_data"));

    if (local_user_data) {
      state.user_data = local_user_data;
      appAxios.defaults.headers.common["Authorization"] =
        "Token " + local_user_data.token;
    }
  },
  set_is_me(state, payload) {
    if (state.user_data) {
      if (state.user_data.username == payload) {
        state.is_me = true;
      } else {
        state.is_me = false;
      }
    } else {
      state.is_me = false;
    }
  },
  set_profile_data(state, payload) {
    state.profile_data = payload;
  },
  set_active_profile(state, payload) {
    state.active_profile = payload;
  },
  download_active_profile(state) {
    if (state.profile_data.profiles.length) {
      state.active_profile = state.profile_data.profiles.filter(
        (x) => x.isActive == true
      )[0];
    }
  },
  log_out(state) {
    state.user_data = null;
    state.profile_data = {};
    state.active_profile = {};
    localStorage.removeItem("user_data");
    router.push("/");
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
  download_profile_data({ dispatch, commit, getters }, payload) {
    // Veri gelene kadar temizlik yapıyoruz
    commit("set_profile_data", []);
    commit("set_active_profile", {});
    commit("set_profile_posts", {});

    if (getters.get_is_me) {
      commit("set_profile_data", getters.get_user_data);
      // profili indirdikten sonra aktif profili set ediyoruz. Eğer aktif profil yoksa postları indirmiyoruz.
      commit("set_profile_posts", []);
      commit("download_active_profile");
      if (getters.get_active_profile) {
        dispatch("download_profile_post", getters.get_active_profile.id);
      } else {
        commit("set_is_post_loading", false);
      }
    } else {
      // DB'den veriyi çekiyoruz
      appAxios
        .post("user/GetUser/", { username: payload })
        .then((response) => {
          commit("set_profile_data", response.data);
          // profili indirdikten sonra aktif profili set ediyoruz. Eğer aktif profil yoksa postları indirmiyoruz.
          commit("download_active_profile");
          if (getters.get_active_profile) {
            dispatch("download_profile_post", getters.get_active_profile.id);
          } else {
            commit("set_is_post_loading", false);
          }
        })
        .catch((e) => console.log(e));
    }
  },
  update_user_data({ dispatch, commit, getters }) {
    appAxios
      .post("user/GetUser/", { username: getters.get_user_data.username })
      .then((response) => {
        let new_data = response.data;
        new_data["token"] = state.user_data["token"];
        commit("set_user_data", new_data);
        commit("set_profile_data", response.data);
        commit("download_active_profile");
        commit("set_is_me", new_data.username);
      })
      .catch((e) => console.log(e));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
