<template>
    <div>
        <footer>
            <div class="footerLinks">
                <router-link to="/contact" class="text-decoration-none">
                    <span>İletişim</span>
                </router-link>
                <router-link to="/about" class="text-decoration-none">
                    <span>Hakkımızda</span>
                </router-link>
                <router-link to="/privacy-policy" class="text-decoration-none">
                    <span>Gizlilik Politikası</span>
                </router-link>
                <router-link to="/terms-of-use" class="text-decoration-none">
                    <span>Kullanım Koşulları</span>
                </router-link>
            </div>
            <p>hedefly.com © 2024. Tüm Hakları Saklıdır.</p>
        </footer>
    </div>
</template>
<style scoped>
footer {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #83929f;
}

.footerLinks {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
}

footer span,
footer p {
    padding: 10px 20px;
    /* color: #212529; */
    color: #83929f;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .footerLinks {
        flex-direction: column;
        align-items: center;
    }
}
</style>