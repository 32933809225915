<template>
  <div class="comment">
    <div v-if="text_area_show" :style="{ boxShadow: '0 2px 8px' + $store.getters.getNumberColor(post.dayCount) }"
      class="modal" :class="{ 'my-gradient-text': post.dayCount > 4500 }">
      <textarea class="textarea" rows="5" cols="50" placeholder="Yorum yap..." v-model="content"></textarea>
      <img @click="addComment" src="@/assets/Images/Feed/send.svg" alt="">
    </div>
    <button class="showButton" @click="comment_list_show = !comment_list_show;">{{ post.comment_list.length < 1 ? " " :
      post.comment_list.length + " Yorum" }}</button>
        <div v-if="comment_list_show" class="commentArea">
          <div v-for="comment in post.comment_list" class="avatar">
            <img :src="avatarCalc(comment.avatar)" alt="">
            <div class="text">
              <router-link class="text-decoration-none" :to="'/u/' + comment.username">
                <div class="name">{{ comment.fullname }}</div>
              </router-link>
              <!--<div class="area">{{ comment.username }}</div> -->
              <p style="white-space: pre-line">{{ comment.content }}</p>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: 'Comment',
  data() {
    return {
      comment_list_show: false,
      content: "",
      text_area_show: true

    };
  },
  props: ["post"],
  components: {

  },
  methods: {
    addComment() {
      let commentData = {
        profilePostId: this.post.id,
        content: this.content,
      }
      this.$store.dispatch("addComment", commentData);
      commentData.username = this.$store.getters.get_user_data.username
      commentData.fullname = this.$store.getters.get_user_data.fullname
      this.post.comment_list.push(commentData)
      this.text_area_show = false
    },
    avatarCalc(avatar_link) {
      if (avatar_link) {
        return "https://api.hedefly.com" + avatar_link;
      }
      return require("@/assets/Images/Header/avatar_thumbnail.png");
    },
  },
  created() {

  },
  computed: {
  }
};
</script>
<style scoped>
.modal {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 460px;
  padding: 10px;
  background-color: transparent;
  border-radius: 16px;
  /* box-shadow: 0 2px 8px;  */
}

.textarea {
  padding: 10px;
  background-color: transparent;
  border-radius: 10px;
  border: transparent;
  resize: none;
  font-family: Poppins;
}

.textarea:focus {
  outline: none;
}

img {
  align-self: flex-end;
  cursor: pointer;
}

.comment .showButton {
  border: none;
  background-color: transparent;
  padding-top: 10px;
  margin-bottom: 10px;
  color: #6C757D;
}

.comment .showButton:hover {
  cursor: pointer;
}

.avatar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding-bottom: 8px;
  position: relative;

}

.avatar img {
  width: 40px;
  height: 40px;
  background-color: #6C757D;
  border-radius: 50%;
  align-self: flex-start;
}

.avatar .text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* color: #212529; */
  color: #6C757D;
  width: fit-content;
}

.avatar .text .name {
  font-size: 16px;
  font-weight: bold;
}

.avatar .text .area {
  font-size: 12px;
  font-weight: 900;
}

.avatar .text p {
  margin: 0;
  padding: 5px 0px;
}

@media screen and (max-width: 730px) {
  .modal {
    width: auto;
  }
}
</style>