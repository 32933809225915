<template>
    <div class="container">
        <div class="splash">
            <div class="slogan-img">
                <img src="@/assets/Images/Home/remember.png" alt="">
            </div>
            <form @submit.prevent="onSubmit()">
                <div class="register">
                    <input v-model="userData.email" type="email" placeholder="e-mail" required>
                    <input v-model="userData.password" type="password" placeholder="şifre" required>
                    <input type="submit" v-if="!isLoading" class="btn" value="Kayıt Ol">
                    <Loading style="width: 100px; height: 100px;" v-else />
                    <small>Kayıt olarak,
                        <router-link to="/terms-of-use">Kullanıcı Koşullarını</router-link> ve
                        <router-link to="/privacy-policy">Gizlilik Politikasını</router-link>
                        kabul etmiş
                        olursunuz.</small>
                    <div class="errorList">
                        <ul>
                            <li v-for="error in errorList">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
        <div class="h1">
            <h1>Hedefiniz veya Uzmanlık Alanınız için <br /> Yaptığınız Çalışmaları Kayıt Altına Alın</h1>
        </div>
        <div class="three-step">
            <img src="@/assets/Images/Home/3stephedefly.png" alt="">
        </div>
        <!-- <div class="example_profiles">
            <p class="title">
                Bazı Profillere Göz At:
            </p>
            <div class="cards">
                <router-link :to="'/u/toplumunbirferdi'" class="text-decoration-none">
                    <div class="profile_card">
                        <img width="100" src="@/assets/Images/Header/tommy.png" alt="">
                        <div class="text">
                            <span class="name">Ferdi Yıldırım</span>
                            <span>Sigarayı Bırakmak</span>
                            <span>4 Gün</span>
                        </div>
                    </div>
                </router-link>
                <router-link :to="'/u/birfrontendci'" class="text-decoration-none">
                    <div class="profile_card">
                        <img width="100" src="@/assets/Images/Header/monica.png" alt="">
                        <div class="text">
                            <span class="name">Bir Frontendci</span>
                            <span>Frontend Web Development</span>
                            <span>6 Gün</span>
                        </div>
                    </div>
                </router-link>
                <router-link :to="'/u/zekicolak'" class="text-decoration-none">
                    <div class="profile_card">
                        <img width="100" src="@/assets/Images/Header/avatar_thumbnail.png" alt="">
                        <div class="text">
                            <span class="name">Zeki Çolak</span>
                            <span>Yapay Zeka Doğal Dil İşleme</span>
                            <span>2 Gün</span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div> -->
        <h2>Hadi Başlayalım!</h2>
        <div class="steps">
            <div class="step">
                <div class="step-image">
                    <Vue3Lottie :animationData="step1" :height="200" :width="200" />
                </div>
                <div class="step-text">Bugün bir söz ver!</div>
            </div>
            <div class="step">
                <div class="step-text">Uzmanlaşacağın alanı veya hedefini belirle.</div>
                <div class="step-image">
                    <Vue3Lottie :animationData="step2" :height="200" :width="200" />
                </div>
            </div>
            <div class="step">
                <div class="step-image">
                    <Vue3Lottie :animationData="step3" :height="200" :width="200" />
                </div>
                <div class="step-text">Her gün bu alandaki çalışmalarını paylaş.</div>
            </div>
            <div class="step">
                <div class="step-text">Aynı alan veya hedef için çalışanlar ile tanış. </div>
                <div class="step-image">
                    <Vue3Lottie :animationData="step4" :height="200" :width="200" />
                </div>
            </div>
            <div class="step">
                <div class="step-image">
                    <Vue3Lottie :animationData="step5" :height="200" :width="200" />
                </div>
                <div class="step-text">Hep birlikte geleceği inşa edin! </div>
            </div>
            <!-- <div class="step">
                <div class="step-text"> Çünkü "Gelecek, bugünden ona hazırlananlara aittir." </div>
                <div class="step-image">
                    <img width="150" src="@/assets/Images/Home/step6.png" alt="">
                </div>
            </div> -->
            <div @click="$store.commit('go_register')" class="btn2"><a><span>ÜCRETSIZ KAYIT OL</span></a></div>

        </div>
        <div class="splash2">

            <div class="ps">
                <p>
                    Her başarının ortak paydası, istikrar ve emektir.
                </p>
                <p>
                    Başarı; her gün, her şeye rağmen, aynı yolda, kararlılıkla yürümeyi
                    gerektirir.
                </p>
                <p>
                    Her gün, odaklandığın alandaki veya hedefindeki çalışmalarını<br>paylaş.
                </p>
                <p>
                    Unutma her gün sadece bir paylaşım yapabilirsin. <br> Profilinin gün sayısı ne kadar istikrarlı
                    olduğunu
                    gösterir.
                </p>
                <p>
                    İstikrarın ile işe alım uzmanlarının, sektör liderlerinin ve yatırımcıların dikkatini çek.
                </p>
                <p>
                    Aynı alanda çalışan insanlarla tanışmak ve ekip kurmak, başarıya giden yolu kolaylaştırır.
                    <br>
                </p>
                <p>
                    Başarıya giden yoldaki emeklerini kaydet. Çalışmaların ile dünyada bir iz bırak!
                </p>
            </div>
        </div>
        
            <!-- <div class="cloud">
                <img src="@/assets/Images/Home/yemek.png" alt="">
                <img src="@/assets/Images/Home/9.png" alt="">
                <img src="@/assets/Images/Home/spor.png" alt="">
                <img src="@/assets/Images/Home/notes.png" alt="">
                <img src="@/assets/Images/Home/biter.png" alt="">
                <img src="@/assets/Images/Home/days.png" alt="">
            </div> -->
        <!-- <div class="splash3">
            <div class="dropping-texts">
                <div>
                    <p>
                        "Sizler, yani yeni Türkiye'nin genç evlatları!<br /> Yorulsanız dahi beni takip edeceksiniz.
                        Dinlenmemek üzere yürümeye karar verenler, asla ve asla yorulmazlar.<br />
                        Türk Gençliği gayeye, bizim yüksek idealimize durmadan, yorulmadan yürüyecektir."
                    </p>
                    <span>
                        Mustafa Kemal Atatürk
                    </span>
                </div>

                <div>
                    <p>
                        "Dehanın %1'i ilham, %99'u da alın teridir."
                    </p>
                    <span>
                        Edison
                    </span>
                </div>

                <div>
                    <p>
                        "Hiçbir şeye ihtiyacımız yok, yalnız bir şeye ihtiyacımız vardır; çalışkan olmak!"
                    </p>
                    <span>
                        Mustafa Kemal Atatürk
                    </span>
                </div>

                <div>
                    <p>"Bazı insanlar olmasını ister, bazıları olmasını bekler, bazıları ise oldurur."</p>
                    <span>
                        Michael Jordan
                    </span>
                </div>


                <div>
                    <p>
                        "Mükemmelliğin bedeli disiplindir. Sıradanlığın maliyeti ise hayal kırıklığıdır.”
                    </p>
                    <span>
                        William Arthur Ward
                    </span>
                </div>

                <div>
                    <p>
                        "Hakiki vatansever vatanını diğerlerine üstün tutan değil,<br /> vatanının övgüye mazhar olması
                        için
                        hareket
                        edendir"
                    </p>
                    <span>
                        Aliya İzzetbegoviç
                    </span>
                </div>

                <div>
                    <p>
                        "Bütün büyüklükler acı çekmekten gelir!"
                    </p>
                    <span>
                        Naval Ravikant
                    </span>
                </div>
            </div>
        </div> -->
      
        <div class="last-step">
            <img class="first" src="@/assets/Images/Home/basamak.png" alt="">
            <img src="@/assets/Images/Home/2745.png" alt="">
        </div>
        <div class="div-btn"> 
             <div style="margin-top: 40px;" @click="$store.commit('go_register')" class="btn2"><a><span>ÜCRETSIZ KAYIT
            OL</span></a>
        </div>
        </div>
      
    </div>

</template>
<script>
import { Vue3Lottie } from 'vue3-lottie'
import Loading from '@/components/Shared/Loading.vue'
import step1 from '@/assets/Images/Home/step1.json'
import step2 from '@/assets/Images/Home/step2.json'
import step3 from '@/assets/Images/Home/step3.json'
import step4 from '@/assets/Images/Home/step4.json'
import step5 from '@/assets/Images/Home/step5.json'


export default {
    data() {
        return {
            step1,
            step2,
            step3,
            step4,
            step5,
            userData: {
                email: "",
                password: ""
            },
            isLoading: false,
            errorList: [],
        }
    },
    components: {
        Vue3Lottie,
        Loading,
    },
    methods: {
        onSubmit() {
            this.errorList = [];
            let numbers = /[0-9]/g;
            if (!this.userData.password.match(numbers)) {
                this.errorList.push("Şifrenizde en az 1 tane rakam olmalıdır")
            }
            if (this.userData.password.length < 8) {
                this.errorList.push("Şifreniz en az 8 karakter içermelidir")
            }
            if (this.errorList.length == 0) {
                this.isLoading = true
                this.$appAxios.post("auth/Register/", this.userData).then((response) => {
                    this.$store.commit("set_user_data", response.data)
                    this.$router.push("/u/" + response.data.username)
                }).catch((e) => {
                    this.errorList.push("Bu mail adresi zaten kayıtlı!")
                    this.isLoading = false
                })
            }
        },
    },
    created() {
    }
}


</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

.splash {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #fefbf6;
    width: 100%;
    overflow: hidden;
    min-height: calc(100vh - 24px);
    gap: 64px;
}

.splash .register {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    color: #495057;
    width: 100%;
    max-width: 400px;
    padding: 16px;
}

.splash .register .btn {
    color: #212529;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 25px;
    margin-top: auto;
    cursor: pointer;
    /* background-color: #495057; */
    background: -webkit-linear-gradient(45deg, rgb(123, 44, 191, .2), rgb(255, 109, 0, .2) 60%);
    transition: box-shadow 0.2s, background 0.2s, color 0.2s;
}

.splash .register .btn:hover {
    background: #f6f7f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
    color: #495057;
}
.div-btn{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn2 {
    text-align: center;
    width: 200px;
    color: #212529;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 25px;
    margin-top: auto;
    margin-bottom: 28px;
    cursor: pointer;
    /* background-color: #495057; */
    background: -webkit-linear-gradient(45deg, rgb(123, 44, 191, .2), rgb(255, 109, 0, .2) 60%);
    transition: box-shadow 0.2s, background 0.2s, color 0.2s;
}

.btn2:hover {
    background: #f6f7f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
    color: #495057;
}

.errorList {
    color: rgb(255, 109, 0, .7);
    padding: 0;
    margin: 0;
}

.splash .register small {
    text-align: center;
}

.splash .register input {
    width: 100%;
    padding: 16px;
    font-size: 24px;
    border-radius: 16px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
}

.splash .register input:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
}

.splash .register input:focus {
    outline: none;
}

.splash .register input:focus::placeholder {
    color: transparent;
}


.slogan-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 440px;
    background-image: url("@/assets/Images/Home/flower.png");
    background-size: 440px 440px;
    background-position: center;
    object-fit: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
}

.slogan-img img {
    width: 440px;
    height: 440px;
    border-radius: 16px;
    transition: all 0.5s ease-in-out;
}

.slogan-img img:hover {
    filter: blur(4px);
    opacity: 0;
}

.h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
}

h1 {
    text-align: center;
    color: #6C757D;
    font-size: 36px;
    font-family: Poppins;
    text-wrap: wrap;
    position: relative;
}

h1::before {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: 8px;
    border-radius: 2px;
    background: -webkit-linear-gradient(45deg, rgb(123, 44, 191, .5), rgb(255, 109, 0, .5) 60%);
    ;
}

h2 {
    text-align: center;
    color: #6C757D;
    font-size: 36px;
    font-family: Poppins;
    text-wrap: wrap;
    position: relative;
}

h2::before {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: 8px;
    border-radius: 2px;
    background: -webkit-linear-gradient(45deg, rgb(123, 44, 191, .5), rgb(255, 109, 0, .5) 60%);
    ;
}

/* .slogan img{
    width: 300px;
    height: 300px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
    border-radius: 16px;
} */

.steps {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 96px;
    justify-content: center;
    align-items: center;
    background-color: #FEFBF6;
}

.step {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 96px;
    border-bottom: 2px solid rgb(123, 44, 191, .2);
    padding-bottom: 96px;
}

.step .step-text {
    cursor: context-menu;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 400px;
    min-width: 400px;
    height: 200px;
    background: -webkit-linear-gradient(45deg, rgb(123, 44, 191, .1), rgb(255, 109, 0, .1) 60%);
    text-align: center;
    color: #6C757D;
    font-weight: 900px;
    font-size: 36px;
    font-family: Poppins;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
    transition: all .2s ease-in-out;
}

.step .step-text:hover {
    transform: scale(1.1);
}

.three-step {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.three-step img {
    width: 440px;
    height: 440px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
    border-radius: 16px;
    transition: all .2s ease-in-out;
}

.three-step img:hover {
    transform: scale(1.1);
}
.last-step{
    padding: 0 180px;
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.last-step img{
    width: 440px;
    height: 440px;
    
}
.last-step .first{
    box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
    border-radius: 16px;
    transition: all .2s ease-in-out;
}
.last-step .first:hover {
    transform: scale(1.1);
}
/* .example_profiles {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f6f7f8;
    padding: 0px 0px 48px 0px;
}

.example_profiles .title {
    text-align: center;
    padding: 0 24px;
    color: #6C757D;
    font-size: 36px;
    font-family: Poppins;
}

.example_profiles .cards {
    width: 90%;
    max-width: 960px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.profile_card {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: -webkit-linear-gradient(45deg, rgb(123, 44, 191, .2), rgb(255, 109, 0, .2) 60%);
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
    width: 300px;
    height: 148px;
    padding: 16px;
    transition: background 500ms;
}

.profile_card:hover {
    color: #83929f;
    background: #f6f7f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
}

.profile_card .text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    color: #6C757D;
    font-family: Poppins;

}

.profile_card .text .name {
    font-size: 22px;
    font-weight: bold;
}

.profile_card img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
} */
.splash2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    min-height: calc(100vh - 24px);
    background-image: url(@/assets/Images/Home/bg5.png);
    background-size: 20%;
    background-repeat: repeat;
}

.ps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ps p {
    text-align: center;
    color: #6C757D;
    font-size: 26px;
    font-family: Poppins;
    padding: 20px 40px;
    margin: 0 0 1.5em;
    /* background-color: #eff0f1; */
    backdrop-filter: blur(30px);
    display: inline-block;
    /* text-shadow: #111 1px 0 10px; */
}

.cloud {
    padding: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 48px;
    flex-wrap: wrap;
   
}

.cloud img {
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
    transition: all .2s ease-in-out;
    width: 300px;
    height: 300px;
}

.cloud img:hover {
    transform: scale(1.1);
}

.cloud img:nth-child(odd) {
    border: 6px solid rgb(123, 44, 191, .2);
}

.cloud img:nth-child(even) {
  border: 6px solid rgb(255, 109, 0, .2);
}

.splash3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-top: 24px;
    color: #495057;
    font-weight: 100;
    overflow: hidden;
    animation: bg 49s linear infinite;
}


.dropping-texts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    text-align: center;
    height: 360px;
    vertical-align: -2px;
}

.dropping-texts>div {
    font-size: 0px;
    opacity: 0;
    margin-left: 30px;
    position: absolute;
    font-weight: 300;
}

.dropping-texts>div:nth-child(1) {
    animation: roll 49s linear infinite 0s;
}

.dropping-texts>div:nth-child(2) {
    animation: roll 49s linear infinite 7s;
}

.dropping-texts>div:nth-child(3) {
    animation: roll 49s linear infinite 14s;
}

.dropping-texts>div:nth-child(4) {
    animation: roll 49s linear infinite 21s;
}

.dropping-texts>div:nth-child(5) {
    animation: roll 49s linear infinite 28s;
}

.dropping-texts>div:nth-child(6) {
    animation: roll 49s linear infinite 35s;
}

.dropping-texts>div:nth-child(7) {
    animation: roll 49s linear infinite 42s;
}


@keyframes roll {
    0% {
        font-size: 0px;
        opacity: 0;
        margin-left: -30px;
        margin-top: 0px;
        transform: rotate(-25deg);
    }

    3% {
        opacity: 1;
        transform: rotate(0deg);
    }

    5% {
        font-size: inherit;
        opacity: 1;
        margin-left: 0px;
        margin-top: 0px;
    }

    20% {
        font-size: inherit;
        opacity: 0;
        margin-left: 0px;
        margin-top: 0px;
        transform: rotate(0deg);
    }

    27% {
        font-size: 0px;
        opacity: 0;
        margin-left: 20px;
        margin-top: 100px;
    }

    100% {
        font-size: 0px;
        opacity: 0.7;
        margin-left: -30px;
        margin-top: 0px;
        transform: rotate(15deg);
    }
}


@keyframes bg {
    0% {
        background: rgb(255, 0, 112, .3);
    }

    3% {
        background: rgb(0, 148, 255, .3);
    }

    20% {
        background: rgb(0, 148, 255, .3);
    }

    23% {
        background: rgb(178, 0, 255, .3);
    }

    40% {
        background: rgb(178, 0, 255, .3);
    }

    43% {
        background: rgb(139, 195, 74, .3);
    }

    60% {
        background: rgb(139, 195, 74, .3);
    }

    63% {
        background: rgb(244, 67, 54, .3);
    }

    80% {
        background: rgb(244, 67, 54, .3);
    }

    83% {
        background: rgb(244, 67, 54, .3);
    }

    100% {
        background: rgb(244, 67, 54, .3);
    }
}

@media screen and (max-width: 830px) {
    .step {
        flex-direction: column;
    }

    .example_profiles .cards {
        flex-direction: column;
        gap: 24px;
    }

    .slogan h1 {
        padding: 0 16px;
    }

    .step .step-text {
        order: 2;
        padding: 24px 12px;
        height: 240px;
    }

    .step .step-image {
        order: 1;
        align-self: center;
        justify-self: center;
    }

    .splash2 {
        flex-direction: column;
        height: auto;
    }
    .cloud{
        flex-direction: column;
        height: auto;
        padding: 24px;
    }
    .last-step{
        padding: 0 24px;
        flex-direction: column;
        gap: 36px;
    }
    .splash3 {
        display: none;
    }

    .slogan-img img,
    .three-step img,
    .last-step img {
        width: 300px;
        height: 300px;
    }

    .slogan-img {
        background-size: 300px 300px;
    }
}

@media screen and (max-width: 450px) {
    .step .step-text {
        min-width: 250px;
    }
}
</style>
