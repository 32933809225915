<template>
  <div>
    <Vue3Lottie :animationData="loading" />
  </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import loading from '@/assets/Images/Loading/loading.json'
export default {
  name: "Loading",
  data() {
    return {
      loading,

    }
  },
  components: {
    Vue3Lottie,
  }
};
</script>
<style></style>