<template>
    <div>
        <div class="feeds">
        <Post/>
        </div>
    </div>
</template>
<script>
import Post from '@/components/Feed/Post.vue'
export default {
    name: 'Feed',
    data() {
        return {
            
        }
    },
    components: {
        Post,
    },
    methods: {

    },
    created() {
    }
}
</script>


<style scoped>

.feeds {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
    gap: 16px;
    padding-top: 96px;
    padding-bottom: 96px;
}


</style>