import { appAxios } from "@/utils/appAxios";
import router from "../../router/index";

const state = {
  feed_posts: [],
  like_list: [],
};
const getters = {
  // state verisini çağırmak için kullanılır
  get_feed_posts(state) {
    return state.feed_posts;
  },
  get_like_list(state) {
    return state.like_list;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  set_feed_posts(state, payload) {
    state.feed_posts = payload;
  },
  set_like_list(state, payload) {
    state.like_list = payload;
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
  download_feed_posts: ({ dispatch, commit, getters }) => {
    commit("set_is_post_loading", true);
    appAxios
      .get("Feed/GetFeed/")
      .then((response) => {
        commit("set_feed_posts", response.data);
        commit("set_is_post_loading", false);
      })
      .catch((e) => console.log(e));
  },
  download_like_list: ({ dispatch, commit }, payload) => {
    appAxios
      .post("post/LikeList/", { profilePostId: payload })
      .then((response) => {
        commit("set_like_list", response.data);
      })
      .catch((e) => console.log(e));
  },
  like: ({ commit, getters, dispatch }, payload) => {
    appAxios
      .post("post/Like/", { profilePostId: payload })
      .then((response) => {})
      .catch((e) => console.log(e));
  },
  dislike: ({ commit, getters, dispatch }, payload) => {
    appAxios
      .post("post/Dislike/", { profilePostId: payload })
      .then((response) => {})
      .catch((e) => console.log(e));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
