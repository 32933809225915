<template>

    <Head>
        <meta name="description"
            content="Hedefiniz veya uzmanlık alanınız için her gün yaptığınız çalışmaları kayıt altına alın. Çalışmalarınız ile iz bırakın." />
        <meta name="title" content="Hedefly - Hedefi için İstikrarla Çalışanların Platformu" />
        <title>Hedefly</title>
    </Head>
    <Feed v-if="$store.getters.is_authenticated"></Feed>
    <Home v-else></Home>
</template>
<script>
import Home from '../components/Index/Home.vue'
import Feed from '../components/Index/FeedMain.vue'


export default {
    data() {
        return {

        }
    },
    components: {
        Home,
        Feed
    },
    methods: {

    },
    created() {
    }
}
</script>
<style></style>