<template>
    <div class="nothing">
      <p>Buralarda henüz gösterilecek bir şey yok...</p>
        <Vue3Lottie :animationData="nothingYet" />
        
    </div>
  </template>
  
<script>
import { Vue3Lottie } from 'vue3-lottie'
import nothingYet from '@/assets/Images/Loading/nothingYet.json'
  export default {
    name: "NothingYet",
    data() {
        return {
        nothingYet,
        }
    },
    components:{
        Vue3Lottie,
    }
  };
</script>
<style>
.nothing{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nothing p{
  text-align: center;
  color: #83929f;
  font-size: 18px;
  padding: 10px;
}

@media screen and (max-width: 730px){
  .nothing p{
    margin: 0 50px;
  }
}
</style>