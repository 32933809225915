import { appAxios } from "@/utils/appAxios";
import router from "../../router/index";

const state = {
  explore_list: [],
};
const getters = {
  // state verisini çağırmak için kullanılır
  get_explore_list(state) {
    return state.explore_list;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  set_explore_list(state, payload) {
    state.explore_list = payload;
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
  follow: ({ commit, getters }, payload) => {
    appAxios
      .post("user/Follow/", { targetId: payload.user_id })
      .then((response) => {
        let new_user_data = getters.get_user_data;
        new_user_data.follows.push(payload);
        commit("set_user_data", new_user_data);
      })
      .catch((e) => console.log(e));
  },
  unfollow: ({ commit, getters }, payload) => {
    appAxios
      .post("user/Unfollow/", { targetId: payload.user_id })
      .then((response) => {
        let new_user_data = getters.get_user_data;
        new_user_data.follows = new_user_data.follows.filter(function (obj) {
          return obj.username !== payload.username;
        });
        commit("set_user_data", new_user_data);
      })
      .catch((e) => console.log(e));
  },
  download_explore_list: ({ commit, getters }) => {
    commit("set_is_post_loading", true);
    appAxios.get("Explore/UserList/").then((response) => {
      commit("set_explore_list", response.data);
      commit("set_is_post_loading", false);
    });
  },
  delete_profile: ({ commit, getters }, payload) => {
    appAxios
      .post("user/DeleteProfile/", { id: payload })
      .then((response) => {
        let user_data = getters.get_user_data;
        user_data.profiles = user_data.profiles.filter(
          (item) => item.id != payload
        );
        commit("set_profile_posts", []);
        commit("set_user_data", user_data);
      })
      .catch((e) => console.log(e));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
