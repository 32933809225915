import axios from "axios";
import router from "../../router/index";
import { appAxios } from "@/utils/appAxios";

const state = {
  comments: [],
};
const getters = {
  getComments(state) {
    return state.comments;
  },
  // state verisini çağırmak için kullanılır
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  ADD_COMMENT(state, commentData) {
    state.comments.push(commentData);
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
  addComment: ({ commit }, payload) => {
    appAxios
      .post("post/Comment/", payload)
      .then((response) => {})
      .catch((e) => console.log(e));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
